import * as echarts from 'echarts';
import { EChartOption, EChartsResponsiveOption } from 'echarts';
import { useEffect, useMemo, useRef } from 'react';
import { ProcessKpi } from '../model';
import { PROCESS_COLORS } from '../utils/DataUtils';

const buildSeries = (datas: number[], color: string): any => {
  return {
    data: datas,
    label: {
      show: true,
      position: 'insideBottom',
      fontSize: '1rem',
      color: '#ffffff',
    },
    itemStyle: {
      emphasis: {
        barBorderRadius: 10,
      },
      normal: {
        barBorderRadius: 10,
      },
      color: color,
    },
    type: 'bar',
  };
};

const unitSeries = {
  data: [0],
  label: {
    show: true,
    position: 'top', // Show label to the right of the bar
    formatter: function () {
      return 'kt';
    },
    align: 'center',
    fontSize: `1.5rem`,
    color: '#000000',
    textBorderColor: '#000000',
  },
  type: 'bar',
  itemStyle: {
    color: '#1492AE',
  },
};
const KpiChart = ({ data }: { data: ProcessKpi }) => {
  const option: EChartOption | EChartsResponsiveOption = useMemo(
    () => ({
      color: ['#14AE5C', '#1492AE', '#E8B931'],
      title: {
        left: '0',
        top: '0',
        right: '5%',
        textStyle: {
          fontSize: 20,
          color: '#000000',
        },
        subtextStyle: {
          fontSize: 20,
        },
      },
      grid: {
        left: '-10%',
        bottom: '5%',
        top: '25%',
        containLabel: false,
      },
      xAxis: {
        show: false,
        type: 'category',
        data: ['a'],
      },
      yAxis: {
        show: false,
        type: 'value',
      },
      series: [],
    }),
    [
    ],
  );

  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      // @ts-ignore
      option.title.text = data.name;
      const datas = [
        data.currentValue,
        data.referenceValue,
        data.simulatedValue,
      ];
      //remove null value
      const filteredDatas = datas.filter((value) => value !== null);
      const myChart = echarts.init(chartRef.current);

      if (data.currentValue) {
        option.series!.push(
          buildSeries([Number(data.currentValue)], PROCESS_COLORS.FORECAST),
        );
      }
      if (data.referenceValue) {
        option.series!.push(
          buildSeries([Number(data.referenceValue)], PROCESS_COLORS.OPTIMAL),
        );
      }
      if (data.simulatedValue) {
        option.series!.push(
          buildSeries([Number(data.simulatedValue)], PROCESS_COLORS.SIMULATION),
        );
      }
      option.series![filteredDatas.length] = unitSeries;
      // @ts-ignore
      option.series![filteredDatas.length].label.formatter = function () {
        return data.unit;
      };
      myChart.setOption(option);
    }
  }, [data, option]);

  return (
    <div>
      <div
        id={data.name}
        ref={chartRef}
        style={{ width: '100%', height: '100%', minHeight: '120px' }}
      ></div>
      <p>{data.description}</p>
    </div>
  );
};
export default KpiChart;
