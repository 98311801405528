import StageLineChart from './StageLineChart';
import StageSlider from './StageSlider';
import { ControlParameter, Stage } from '../model';
import styles from './stageline.module.scss';

const StageLine = ({
  stageData,
  lastStage,
}: {
  stageData: Stage;
  lastStage?: boolean;
}) => {
  return (
    <div
      className={`${styles.StageWraper} ${lastStage ? styles.lastStage : null}`}
    >
      <div className={`mt-8 ${styles.stageContent} `}>
        {stageData.enabled && (
          <div>
            <div className={`text-white border-b-2`}>
              {stageData.name} Stage
            </div>
            <div className={`mt-4 grid-cols-1 grid gap-8`}>
              {!stageData.quality_indicators.length ? (
                <div className={`text-white`}>No quality indicators</div>
              ) : null}
              {stageData.quality_indicators.map((indicator) => {
                return (
                  <StageLineChart
                    key={indicator.tag}
                    childKey={indicator.tag}
                    indicatorData={indicator}
                  ></StageLineChart>
                );
              })}
            </div>
            <div className={`text-white border-b-2 mt-12 flex`}>
              {stageData.name} Stage
            </div>
            <div className={`mt-4 grid grid-cols-2 gap-4`}>
              {stageData.control_parameters.map(
                (control: ControlParameter, index) => {
                  return (
                    <StageSlider
                      key={control.tag + index}
                      config={control}
                    ></StageSlider>
                  );
                },
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StageLine;
