import MultipleThumbs from './MultipleThumbs';
import React from 'react';
import { ControlParameter } from '../model';
import useProcess from '../hooks/ProcessHook';

const StageSlider = ({ config }: { config: ControlParameter }) => {
  const { isSimulation, onSimulate } = useProcess();

  return (
    <>
      <div className={`bg-gray-600 text-white rounded-2xl p-4`}>
        <div className={`flex`}>
          <div>
            <p className={`font-bold`}>{config.name}</p>
            <p>{config.description || '-'}</p>
          </div>
          <svg
            className={`ms-auto ${config.can_optimize ? 'cursor-pointer' : ''}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="12"
              fill={config.can_optimize ? '#FC5A9F' : '#757575'}
            />
            <path
              d="M7.77219 16H6.66424L9.86879 7.27273H10.9597L14.1642 16H13.0563L10.4483 8.65341H10.3801L7.77219 16ZM8.18129 12.5909H12.6472V13.5284H8.18129V12.5909ZM16.5804 7.27273V16H15.5236V7.27273H16.5804Z"
              fill="white"
            />
          </svg>
        </div>
        <MultipleThumbs
          rtl={false}
          min={config.min_value}
          max={config.max_value}
          current={
            config.value
              ? config.value > config.max_value
                ? config.max_value
                : config.value
              : config.min_value
          }
          isSimulation={isSimulation}
          onSimulate={(simValue: number) => {
            onSimulate({ ...config, simulation_value: simValue });
          }}
        ></MultipleThumbs>
      </div>
    </>
  );
};

export default StageSlider;
