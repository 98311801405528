import { Process, Stage } from '../model';
import styles from './ProcessMiniMap.module.scss';

const ProcessMiniMap = ({
  process,
  updateData,
}: {
  process: Process | null;
  updateData: (id: number, updatedField: Partial<Stage>) => void;
}) => {
  const toggleStage = (stage: Stage) => {
    updateData(stage.id, { enabled: !stage.enabled });
  };

  return (
    <div className={`${styles.steps}`}>
      {process?.stages && process?.stages
        .sort((a, b) => a.stage_order - b.stage_order)
        .map((stage: Stage, index: number) => {
          return (
            <div
              key={`stage-mini` + index}
              className={`flex ${styles.step}  ${stage.enabled ? styles.active : ''}`}
            >
              <button
                className={` ${styles.stageButton} rounded-full bg-gray-400 text-white px-4 py-2 min-w-24 relative z-10}`}
                onClick={() => {
                  toggleStage(stage);
                }}
              >
                {stage.name}
              </button>
            </div>
          );
        })}
    </div>
  );
};
export default ProcessMiniMap;
