import KpiChart from '../../components/KpiChart';
import StageLine from '../../components/StageLine';
import { Suspense } from 'react';
import { ProcessKpi, Stage } from '../../model';
import '../../styles/_variables.scss';
import './dashboard.scss';
import ProcessToolbar from '../../components/ProcessToolbar';
import ProcessMiniMap from '../../components/ProcessMiniMap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DashboardProvider from '../../components/DashboardProvider';
import useProcess from '../../hooks/ProcessHook';

const Dashboard = () => {
  const { processDetail, processKpis, error, loading, handleToggleStage } =
    useProcess();

  //loading
  if (loading) {
    return <div>Loading...</div>;
  } else {
    if (error) {
      return <div>{error}</div>;
    }
    return (
      <div className={`min-h-screen bg-slate-700 relative`}>
        <ProcessToolbar></ProcessToolbar>
        <div className={`pt-16 p-4 flex gap-6`}>
          {processKpis.map((kpi: ProcessKpi, index: number) => {
            return (
              <div
                className={`bg-gray-500 min-w-56 rounded-3xl p-2`}
                key={`kpi_chart${index}`}
              >
                <KpiChart data={kpi}></KpiChart>
              </div>
            );
          })}
        </div>
        <div className={`px-6 mt-12`}>
          <ProcessMiniMap
            process={processDetail}
            updateData={handleToggleStage}
          ></ProcessMiniMap>
        </div>
        <TransitionGroup className={` overflow-x-auto whitespace-nowrap`}>
          {processDetail?.stages &&
            processDetail?.stages
              .filter((stage: Stage) => stage.enabled)
              .sort((a, b) => a.stage_order - b.stage_order)
              .map((stage: Stage) => {
                return (
                  <CSSTransition
                    key={stage.tag}
                    timeout={200}
                    classNames="item"
                  >
                    <Suspense key={stage.id}>
                      <StageLine
                        stageData={stage}
                        lastStage={false}
                      ></StageLine>
                    </Suspense>
                  </CSSTransition>
                );
              })}
        </TransitionGroup>
      </div>
    );
  }
};

const DashboardWrapper = () => {
  return (
    <DashboardProvider>
      <Dashboard></Dashboard>
    </DashboardProvider>
  );
};

export default DashboardWrapper;
