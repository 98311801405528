import axios, { AxiosInstance, AxiosResponse } from 'axios';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL || 'https://staging-vm-01.dev.az.greenfactory.ai:8444/api', // Set your API base URL here
      timeout: 10000, // Set a timeout for requests
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Optionally add interceptors for request or response
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // You can add authorization headers or any custom headers here
        const token = localStorage.getItem('token'); // Example of adding token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handle error responses globally
        if (error.response) {
          // Handle specific error codes like 401, 403, 500, etc.
          if (error.response.status === 401) {
            // Handle unauthorized access
          }
        }
        return Promise.reject(error);
      }
    );
  }

  // Example GET method
  public async get<T>(url: string, params?: Record<string, any>): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, { params });
    return response.data;
  }

  // Example POST method
  public async post<T, D>(url: string, data: D, abortController?: AbortController): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, {
      signal: abortController?.signal,
    });
    return response.data;
  }

  // Example PUT method
  public async put<T, D>(url: string, data: D): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(url, data);
    return response.data;
  }

  // Example DELETE method
  public async delete<T>(url: string): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(url);
    return response.data;
  }
}

const apiService = new ApiService();
export default apiService;
