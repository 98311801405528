import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { QualityIndicator, QualityIndicatorValueItem } from '../model';
import { PROCESS_COLORS, roundNumberPad2 } from '../utils/DataUtils';

let option: any;
option = {
  title: {
    text: 'Primary title',
    textStyle: {
      color: 'white',
    },
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    show: false,
    data: ['A', 'B', 'C', 'D', 'E'],
  },
  grid: {
    left: '0%',
    right: '2%',
    bottom: '0%',
    containLabel: true,
  },
  toolbox: {},
  xAxis: {
    type: 'category',
    data: ['11:00', '12:00', 'Now', '14:00', '15:00', '16:00'],
    axisTick: {
      show: false,
    },
    axisLabel: {
      color: '#ffffff',
    },
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisLabel: {
      show: true,
      color: '#ffffff',
      formatter: function (value: any) {
        // Only show labels within the range
        if (value >= 60 && value <= 110) {
          return value;
        } else {
          return '';
        }
      },
    },
    interval: 0
  },
  series: [
    {
      name: 'A',
      type: 'line',
      data: [100, 80, 65],
      itemStyle: {
        color: '#B3B3B3',
      },
      markArea: {
        itemStyle: {
          color: 'rgba(128, 128, 128, 0.3)', // Grey background with 30% opacity
        },
        data: [
          [
            {
              yAxis: 60,
            },
            {
              yAxis: 100,
            },
          ],
        ],
      },
      lineStyle: { color: '#B3B3B3' },
      markPoint: {
        symbol: 'pin',
        itemStyle: { color: '#B3B3B3' },
      },
    },
    {
      name: 'B',
      type: 'line',
      data: [120, 90, 28],
      itemStyle: {
        color: PROCESS_COLORS.FORECAST,
      },
      lineStyle: { color: PROCESS_COLORS.FORECAST },
    },
    {
      name: 'C',
      type: 'line',
      itemStyle: {
        color: '#14AE5C',
      },
      data: [null, null, 28, 90, 70, 85],
      lineStyle: { color: '#14AE5C' },
    },
    {
      name: 'D',
      type: 'line',
      itemStyle: {
        color: '#1492AE',
      },
      data: [null, null, 28, 80, 65, 40],
      lineStyle: { color: '#1492AE' },
    },
    {
      name: 'E',
      type: 'line',
      itemStyle: {
        color: '#E8B931',
      },
      data: [null, null, 28, 74, 25, 37],
      lineStyle: { color: '#E8B931' },
    },
  ],
};

const StageLineChart = ({
  childKey,
  indicatorData,
}: {
  childKey: string;
  indicatorData: QualityIndicator;
}) => {
  const chartRef = useRef(null);
  // const [actualData, setActualData] = useState<QualityIndicatorValueItem[]>();
  const [measuredData, setMeasuredData] = useState<QualityIndicatorValueItem[]>(
    [],
  );
  const [forecastData, setForecastData] = useState<QualityIndicatorValueItem[]>(
    [],
  );
  const [simulationData, setSimulationData] = useState<
    QualityIndicatorValueItem[]
  >([]);

  useEffect(() => {
    // Function to call the API
    const fetchData = async () => {
      try {
        setMeasuredData(indicatorData.measuredData || []);
        setForecastData(indicatorData.forecastedData || []);
        setSimulationData(indicatorData.simulationData || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [indicatorData]);

  function formatTimeHHMM(timestamp: string) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const mergeData = useCallback((
    measuredData: QualityIndicatorValueItem[],
    forcastData: QualityIndicatorValueItem[],
    simulationData: QualityIndicatorValueItem[],
  ) => {
    const combinedData: any = {};

    [measuredData, forcastData, simulationData].forEach((data, index) => {
      data.forEach((item) => {
        const roundedTimestamp: string = formatTimeHHMM(item.timestamp);
        if (!combinedData[roundedTimestamp]) {
          combinedData[roundedTimestamp] = {
            timestamp: roundedTimestamp,
            line1: null,
            line2: null,
            line3: null,
          };
        }
        combinedData[roundedTimestamp][`line${index + 1}`] = item.value;
      });
    });

    const sortedData: any = Object.values(combinedData).sort((a: any, b: any) =>
      a.timestamp.localeCompare(b.timestamp),
    );

    return {
      xAxis: sortedData.map((item: any) => item.timestamp),
      measuredData: sortedData.map((item: any) => item.line1),
      forcastData: sortedData.map((item: any) => item.line2),
      simulationData: fillMissingValues(
        sortedData.map((item: any) => item.line3),
      ),
    };
  }, []);

  function fillMissingValues(arr: any) {
    let lastValid: any = null;
    return arr.map((value: any) => {
      if (value !== null) {
        lastValid = value;
        return value;
      }
      return lastValid;
    });
  }

  useEffect(() => {
    if (chartRef.current) {
      const chartData = mergeData(measuredData, forecastData, simulationData);
      echarts.dispose(chartRef.current);
      let myChart = echarts.init(chartRef.current);
      option.xAxis.data = chartData.xAxis;
      const actualSeries = {
        name: 'Actual',
        type: 'line',
        data: chartData.measuredData,
        itemStyle: {
          color: PROCESS_COLORS.ACTUAL,
        },
        markArea: {
          itemStyle: {
            color: 'rgba(128, 128, 128, 0.3)',
          },
          data: [
            [
              {
                yAxis: indicatorData.optimal_range_min,
              },
              {
                yAxis: indicatorData.optimal_range_max,
              },
            ],
          ],
        },
        lineStyle: { color: PROCESS_COLORS.ACTUAL },
        markPoint: {
          symbol: 'pin',
          itemStyle: { color: PROCESS_COLORS.ACTUAL },
        },
      };
      const forecastSeries = {
        name: 'Forecast',
        type: 'line',
        data: chartData.forcastData,
        itemStyle: {
          color: PROCESS_COLORS.FORECAST,
        },
        lineStyle: { color: PROCESS_COLORS.FORECAST },
      };
      const simulationSeries = {
        name: 'Simulation',
        type: 'line',
        data: chartData.simulationData,
        itemStyle: {
          color: PROCESS_COLORS.SIMULATION,
        },
        lineStyle: { color: PROCESS_COLORS.SIMULATION },
      };
      option.series = [actualSeries, forecastSeries];
      if (simulationData?.length) {
        option.series.push(simulationSeries);
      }
      option.yAxis = {
        type: 'value',
        position: 'right',
        axisLabel: {
          show: true,
          color: '#ffffff',
          customValues: [
            roundNumberPad2(indicatorData.optimal_range_min),
            roundNumberPad2(indicatorData.optimal_range_max),
          ],
        },
      };

      option.title.text = indicatorData.name;
      myChart.setOption(option);
    }
  }, [measuredData, forecastData, indicatorData, simulationData, mergeData]);

  return (
    <div>
      <div id={childKey} ref={chartRef} style={{ width: '100%', height: 220 }}>
        <div className=" shadow rounded-md w-full">
          <div className="animate-pulse flex">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageLineChart;
