import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { Range } from 'react-range';
import {
  PROCESS_COLORS,
  roundNumberPad2,
  useDebounce,
} from '../utils/DataUtils';

const STEP = 0.01;
const MIN = 0;
const MAX = 100;

const SIMULATION_INDEX = 2;
const ACTUAL_INDEX = 0;
const OPTIMAL_INDEX = 1;

const Track = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: '35px',
        paddingBottom: '24px',
      }}
    >
      {children}
    </div>
  );
};

const MultipleThumbs: React.FC<{
  rtl: boolean;
  min: number;
  max: number;
  current: number | undefined;
  optimal?: number | undefined;
  simulation?: number | undefined;
  isSimulation?: boolean;
  onSimulate: (simValue: number) => void;
}> = ({
  rtl,
  min = MIN,
  max = MAX,
  current = 0,
  optimal = 0,
  simulation = 0,
  isSimulation = false,
  onSimulate,
}) => {
  const [values, setValues] = React.useState([current, optimal, simulation]);
  const COLORS = [
    PROCESS_COLORS.ACTUAL,
    PROCESS_COLORS.OPTIMAL,
    PROCESS_COLORS.SIMULATION,
  ];

  useEffect(() => {
    if (isSimulation) {
      setValues([current, optimal, current]);
    } else {
      setValues([current, optimal]);
    }
  }, [current, optimal, simulation, isSimulation]);

  function emitSimulate() {
    if (isSimulation) {
      onSimulate(values[SIMULATION_INDEX]);
    }
  }

  const debounceFn = useDebounce(emitSimulate, 500);

  const handleInputChange = (values: number[]) => {
    setValues(values);
    debounceFn();
  };

  useEffect(() => {
    return () => {
      // if (debounceFn) debounceFn.cancel(); // Cleanup the debounce on component unmount or when values change
    };
  }, []);

  return (
    <Track>
      <Range
        allowOverlap
        values={values}
        step={STEP}
        min={min}
        max={max}
        rtl={rtl}
        onChange={handleInputChange}
        renderTrack={({ props, children }) => (
          <div
            // onMouseDown={props.onMouseDown}
            // onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '3px',
              display: 'flex',
              width: '100%',
            }}
          >
            <span className={`absolute left-0 text-xs pt-1`}>{min}</span>

            <div
              ref={props.ref}
              style={{
                height: '3px',
                width: '100%',
                background: '#ccc',
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
            <span className={`absolute right-0 text-xs pt-1`}>
              {roundNumberPad2(max)}
            </span>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: '0',
              width: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: isSimulation && index === ACTUAL_INDEX ? '3' : 'auto',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: index === OPTIMAL_INDEX ? '-40px' : 'unset',
                bottom: index !== OPTIMAL_INDEX ? '-40px' : 'unset',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                pointerEvents: index !== SIMULATION_INDEX ? 'none' : 'auto',
              }}
            >
              {index === OPTIMAL_INDEX ? (
                <svg
                  width="36"
                  height="28"
                  viewBox="0 0 36 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 9C0 4.58172 3.58172 1 8 1H28C32.4183 1 36 4.58172 36 9V17H0V9Z"
                    fill={COLORS[index]}
                  />
                  <path
                    d="M18.0004 28L0 17L36 17L18.0004 28Z"
                    fill={COLORS[index]}
                  />
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="white"
                    fontSize="10"
                  >
                    {values[index].toFixed(2)}
                  </text>
                </svg>
              ) : (
                <svg
                  width="36"
                  height="27"
                  viewBox="0 0 36 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36 19C36 23.4183 32.4183 27 28 27L8 27C3.58172 27 0 23.4183 0 19V11L36 11V19Z"
                    fill={COLORS[index]}
                  />
                  <path
                    d="M17.9996 0L36 11.25L0 11.25L17.9996 0Z"
                    fill={COLORS[index]}
                  />
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="white"
                    fontSize="10"
                  >
                    {values[index].toFixed(2)}
                  </text>
                </svg>
              )}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? COLORS[index] : '#CCC',
              }}
            />
          </div>
        )}
      />
    </Track>
  );
};

export default MultipleThumbs;
