import { useContext } from 'react';
import { ProcessContext } from '../components/DashboardProvider';

const useProcess = () => {
  const context = useContext(ProcessContext);
  if (!context) {
    throw new Error('useProcess must be used within a ProcessProvider');
  }
  return context;
};
export default useProcess;
