import { PROCESS_COLORS } from '../utils/DataUtils';
import useProcess from '../hooks/ProcessHook';

const ProcessToolbar = () => {
  const { isSimulation, toggleSimulation } = useProcess();
  const items = [
    { title: 'Simulation', color: PROCESS_COLORS.SIMULATION },
    { title: 'Optimal', color: PROCESS_COLORS.OPTIMAL },
    { title: 'Forecast', color: PROCESS_COLORS.FORECAST },
    { title: 'Actual', color: PROCESS_COLORS.ACTUAL },
    { title: 'Past predict', color: PROCESS_COLORS.PAST_PREDICT },
  ];
  return (
    <>
      <div
        className={`bg-green-800 text-white text-xl py-2 px-4 absolute top-0 w-full z-50 flex sticky`}
      >
        {items.map((item, index) => {
          return (
            <span
              key={index}
              className={`ml-8 text-sm flex justify-center items-center`}
            >
              <span
                className={`rounded-full h-3 w-3 inline-block me-2`}
                style={{ backgroundColor: item.color }}
              ></span>
              {item.title}
            </span>
          );
        })}
        <span className={`ms-auto`}>
          <button
            className={`rounded bg-gray-900 px-3 py-1 text-sm ${isSimulation ? 'bg-pink-500' : ''}`}
            onClick={() => {
              toggleSimulation();
            }}
          >
            Simulation
          </button>
        </span>
      </div>
    </>
  );
};
export default ProcessToolbar;
